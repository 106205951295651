import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notification } from '../../../core/models/notification.model';
import { CommonModule } from '@angular/common';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'mp-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class SnackbarComponent {
    @Input() public notification?: Notification;
    @Output() public closeNotification = new EventEmitter<void>();
}
