import {
    HttpContextToken,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { Notification } from '../models/notification.model';
import { Router } from '@angular/router';
import { Dialog } from '@angular/cdk/dialog';
import { AuthService } from 'src/app/shared/modules/auth/auth.service';

export const SkipErrorInterceptorContextToken = new HttpContextToken<(err: HttpErrorResponse) => boolean>(
    () => () => false,
);

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private notificationService: NotificationService,
        private _authService: AuthService,
        private router: Router,
        private dialog: Dialog,
    ) {}

    public intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(httpRequest).pipe(
            tap({
                error: (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        this.handleError(httpRequest, err);
                    }
                },
            }),
        );
    }

    private handleError(req: HttpRequest<any>, err: HttpErrorResponse) {
        const shouldSkip = req.context.get(SkipErrorInterceptorContextToken)(err);

        if (shouldSkip) {
            return;
        }

        let notification: Notification = {
            type: 'danger',
            message: err.error.message || 'Generic server error',
            timeout: 5000,
        };
        this.notificationService.push(notification);
        if (err.status === 401 || err.status === 403 || err.status === 0) {
            this._authService.logout();
            this.dialog.closeAll();
            const returnUrl = this.router.routerState.snapshot.url;
            this.router.navigate(['auth', 'login'], { queryParams: { returnUrl } });
        }
    }
}
