import { InjectionToken } from '@angular/core';

export const SessionStorage = new InjectionToken<Storage>('SessionStorage', {
    providedIn: 'root',
    factory: () => sessionStorage,
});

export const LocalStorage = new InjectionToken<Storage>('LocalStorage', {
    providedIn: 'root',
    factory: () => localStorage,
});
