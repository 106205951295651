import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
    BehaviorSubject,
    NEVER,
    Observable,
    catchError,
    distinctUntilChanged,
    firstValueFrom,
    map,
    of,
    shareReplay,
    skip,
    switchMap,
    timer,
} from 'rxjs';
import { SkipErrorInterceptorContextToken } from 'src/app/core/interceptors/error-interceptor';
import { LoginRequest } from '../../../features/auth/models/login-request';
import { LoginResponse } from '../../../features/auth/models/login-response';
import { MerchantMfaApiService } from 'src/app/features/mfa/merchant-mfa-api.service';
import { SessionStorage } from 'src/app/core/services/storage';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserDetails } from 'src/app/features/auth/models/user-details';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public user$!: Observable<UserDetails | null>;
    private _user: UserDetails | null = null;
    public get user(): UserDetails | null {
        return this._user;
    }

    private _loginResponse$: BehaviorSubject<LoginResponse | null> = new BehaviorSubject<LoginResponse | null>(null);
    private _userId$: Observable<string | null> = this._loginResponse$.pipe(
        map((loginResponse) => loginResponse?.loggedUser.userId ?? null),
    );
    private get _userId(): string | null {
        return this.user?.userId ?? null;
    }

    public loginResponse$ = this._loginResponse$.asObservable();

    private readonly _storage = inject(SessionStorage);
    private readonly _loginResponseKey = 'loginResponse';

    constructor(
        private _http: HttpClient,
        private _mfaApiService: MerchantMfaApiService,
    ) {
        this.initLoginResponse();
        this.initUser$();
    }

    public async login(data: LoginRequest): Promise<UserDetails> {
        const loginResponse = await this.executeLogin(data);

        const userResponse = await this.setLoggedIn(loginResponse);

        return userResponse;
    }

    public async logout(userId: string = this._userId!): Promise<void> {
        if (userId) {
            this._loginResponse$.next(null);
            await this.executeLogout(userId);
        }
    }

    public async setLoggedIn(loginResponse: LoginResponse): Promise<UserDetails> {
        this._loginResponse$.next(loginResponse);
        // Wait for the userinfo
        return firstValueFrom((this.user$ as Observable<UserDetails>).pipe(skip(1)));
    }

    private executeLogin(data: LoginRequest): Promise<LoginResponse> {
        const url = '/auth/login';
        return firstValueFrom(
            this._http
                .post<LoginResponse>(url, data, {
                    context: new HttpContext().set(
                        SkipErrorInterceptorContextToken,
                        this._mfaApiService.isOTPRequiredError,
                    ),
                })
                .pipe(
                    catchError((err) => {
                        this._mfaApiService.mapOTPError(err);
                    }),
                ),
        );
    }

    private getUser(): Observable<UserDetails> {
        const url = `/auth/userinfo`;
        return this._http.get<UserDetails>(url);
    }

    private executeLogout(userId: string): Promise<void> {
        const url = '/auth/' + userId + '/logout';
        return firstValueFrom(this._http.post<void>(url, null));
    }

    private initLoginResponse() {
        const storedLoginResponse = this._storage.getItem(this._loginResponseKey);
        if (storedLoginResponse) {
            this._loginResponse$.next(JSON.parse(storedLoginResponse));
        }

        this._loginResponse$.pipe(takeUntilDestroyed()).subscribe((loginResponse) => {
            this._storage.setItem(this._loginResponseKey, JSON.stringify(loginResponse));
        });
    }

    private initUser$() {
        this.user$ = this._userId$.pipe(
            switchMap((userId) => {
                if (!userId) {
                    return of(null);
                }

                // use this as a heartbeat/keep alive for the cookie
                return timer(0, 10 * 60 * 1000).pipe(
                    switchMap(() => this.getUser()),
                    distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
                    catchError(() => {
                        // This will cause us to get 401 somewhere eventually and go back to login page
                        // If needed we could emit some error from this service and do the nav anyway
                        this._loginResponse$.next(null);
                        return NEVER;
                    }),
                );
            }),
            shareReplay(1),
        );

        this.user$.pipe(takeUntilDestroyed()).subscribe((user) => (this._user = user));
    }
}
