import { Injectable } from '@angular/core';
import { MfaModalService } from './mfa-modal/mfa-modal.service';

export interface PasswordChange {
    oldPassword: string;
    newPassword: string;
    otp: string;
}

export interface MfaDisable {
    password: string;
    otp: string;
}

export interface MfaEnable {
    password: string;
    otp: string;
}

export interface MfaConfig {
    qrCode: string;
    secretKey: string;
}

export abstract class OTPError extends Error {}
export class OTPRequiredError extends OTPError {}
export class OTPInvalidError extends OTPError {}
export class OTPReuseError extends OTPError {}

@Injectable()
export abstract class MfaApiService {
    public abstract verifyPassword(password: string): Promise<void>;
    public abstract disableMfa(mfaDisable: MfaDisable): Promise<void>;
    public abstract generateMfaConfig(): Promise<MfaConfig>;
    public abstract enableMfa(mfaEnable: MfaEnable): Promise<void>;
    public abstract changePassword(passwordChange: PasswordChange): Promise<void>;
    public abstract mapOTPError(err: any): never;
}

@Injectable({ providedIn: 'root' })
export class MfaService {
    public get mfaError() {
        return this._mfaError;
    }

    private _mfaError?: OTPInvalidError | OTPReuseError;

    constructor(
        private _mfaApiService: MfaApiService,
        private _mfaModalService: MfaModalService,
    ) {}

    public async verifyPassword(password: string): Promise<void> {
        this._mfaModalService.toggleModalSpinner(true);
        try {
            await this._mfaApiService.verifyPassword(password);
        } finally {
            this._mfaModalService.toggleModalSpinner(false);
        }
    }

    public async disableMfa(mfaDisable: MfaDisable): Promise<void> {
        this._mfaError = undefined;
        this._mfaModalService.toggleModalSpinner(true);
        try {
            await this._mfaApiService.disableMfa(mfaDisable).catch((e) => this._mfaApiService.mapOTPError(e));
        } catch (e) {
            if (e instanceof OTPInvalidError || e instanceof OTPReuseError) {
                this._mfaError = e;
            }
            throw e;
        } finally {
            this._mfaModalService.toggleModalSpinner(false);
        }
    }

    public async generateMfaConfig(isRegenerating: boolean = false): Promise<MfaConfig> {
        this._mfaModalService.toggleModalSpinner(!isRegenerating);
        try {
            return await this._mfaApiService.generateMfaConfig();
        } finally {
            this._mfaModalService.toggleModalSpinner(false);
        }
    }

    public async enableMfa(mfaEnable: MfaEnable): Promise<void> {
        this._mfaError = undefined;
        this._mfaModalService.toggleModalSpinner(true);
        try {
            await this._mfaApiService.enableMfa(mfaEnable).catch((e) => this._mfaApiService.mapOTPError(e));
        } catch (e) {
            if (e instanceof OTPInvalidError || e instanceof OTPReuseError) {
                this._mfaError = e;
            }
            throw e;
        } finally {
            this._mfaModalService.toggleModalSpinner(false);
        }
    }

    public async changePassword(passwordChange: PasswordChange): Promise<void> {
        this._mfaError = undefined;
        this._mfaModalService.toggleModalSpinner(true);
        try {
            await this._mfaApiService.changePassword(passwordChange).catch((e) => this._mfaApiService.mapOTPError(e));
        } catch (e) {
            if (e instanceof OTPInvalidError || e instanceof OTPReuseError) {
                this._mfaError = e;
            }
            throw e;
        } finally {
            this._mfaModalService.toggleModalSpinner(false);
        }
    }
}
