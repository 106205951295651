import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MfaModalService {
    public isModalLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public toggleModalSpinner(isLoading: boolean) {
        this.isModalLoading$.next(isLoading);
    }
}
