import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class Utils {

    private constructor() {
        // nothing here
    }

    /**
     * Encodes the string in input into escaped (URL safe) base64 format.
     * @param str string to be encoded
     */
    public static base64encode(str: string): string {
        return btoa(encodeURIComponent(str));
    }

    /**
     * Decodes the base64 URL safe string in the original string.
     * @param base64str base64 URL safe escaped string
     */
    public static base64decode(base64str: string): string {
        return decodeURIComponent(atob(base64str));
    }
}
