import { ComponentRef, Injectable } from '@angular/core';
import { Subject, Subscription, concat, merge, of, switchMap, timer } from 'rxjs';
import { Notification } from '../models/notification.model';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private notification$: Subject<Notification | null> = new Subject<Notification | null>();
    private showNotification$ = this.notification$.pipe(
        switchMap((notification) => {
            if (!notification || !notification.timeout) {
                return of(notification);
            }

            return concat(of(notification), timer(notification.timeout), of(null));
        }),
    );

    private _overlayRef?: OverlayRef;
    private _componentRef?: ComponentRef<SnackbarComponent>;
    private _closeNotificationSubscription: Subscription = Subscription.EMPTY;

    constructor(private _overlay: Overlay) {
        this.showNotification$.subscribe((notification) => {
            if (!notification) {
                this.hideNotification();
            } else {
                this.showNotification(notification);
            }
        });
    }

    public push(notification: Notification): void {
        this.notification$.next(notification);
    }

    private showNotification(notification: Notification) {
        if (!this._componentRef) {
            this._overlayRef = this._overlay.create({
                positionStrategy: this._overlay.position().global().centerHorizontally().bottom('10%'),
                hasBackdrop: true,
                backdropClass: '',
            });
            const snackBarPortal = new ComponentPortal(SnackbarComponent);
            this._componentRef = this._overlayRef.attach(snackBarPortal);
            this._closeNotificationSubscription = merge(
                this._componentRef.instance.closeNotification,
                this._overlayRef.backdropClick(),
            ).subscribe(() => this.notification$.next(null));
        }
        this._componentRef.instance.notification = notification;
    }

    private hideNotification() {
        if (!this._overlayRef) {
            return;
        }

        this._closeNotificationSubscription.unsubscribe();
        this._componentRef = undefined;
        this._overlayRef.detach();
        this._overlayRef.dispose();
        this._overlayRef = undefined;
    }
}
