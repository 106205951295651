import { EventEmitter, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
    MfaApiService,
    MfaDisable,
    MfaConfig,
    MfaEnable,
    PasswordChange,
    OTPRequiredError,
    OTPInvalidError,
    OTPReuseError,
} from './mfa.service';
import { HttpClient, HttpContext, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Utils } from 'src/app/core/utils/utils';
import { SkipErrorInterceptorContextToken } from 'src/app/core/interceptors/error-interceptor';

interface MFAActionRequest {
    password: string;
    otp: string;
}

interface VerifyPasswordRequest {
    password: string;
}

interface ChangePasswordRequest {
    oldPassword: string;
    newPassword: string;
    otp: string;
}

@Injectable({ providedIn: 'root' })
export class MerchantMfaApiService extends MfaApiService {
    public readonly twoFactorRequiredChanged: EventEmitter<boolean> = new EventEmitter();

    constructor(private _httpClient: HttpClient) {
        super();
    }

    public override async verifyPassword(password: string): Promise<void> {
        const verifyPasswordRequest: VerifyPasswordRequest = { password: Utils.base64encode(password) };
        return firstValueFrom(
            this._httpClient.post<void>('/auth/verify-password', verifyPasswordRequest, {
                context: new HttpContext().set(
                    SkipErrorInterceptorContextToken,
                    (err) => err.status === HttpStatusCode.BadRequest && err.error.message === 'Invalid credentials',
                ),
            }),
        );
    }

    public override async disableMfa(mfaDisable: MfaDisable): Promise<void> {
        const disableMfaRequest: MFAActionRequest = {
            ...mfaDisable,
            password: Utils.base64encode(mfaDisable.password),
        };
        await firstValueFrom(
            this._httpClient.post<void>('/two-factor-auth/disable', disableMfaRequest, {
                context: new HttpContext().set(
                    SkipErrorInterceptorContextToken,
                    (err) => this.isOTPInvalidError(err) || this.isOTPReuseError(err),
                ),
            }),
        );
        this.twoFactorRequiredChanged.emit(false);
    }

    public override async generateMfaConfig(): Promise<MfaConfig> {
        return firstValueFrom(this._httpClient.post<MfaConfig>('/two-factor-auth/generate-config', null));
    }

    public override async enableMfa(mfaEnable: MfaEnable): Promise<void> {
        const enableMfaRequest: MFAActionRequest = {
            ...mfaEnable,
            password: Utils.base64encode(mfaEnable.password),
        };
        await firstValueFrom(
            this._httpClient.post<void>('/two-factor-auth/enable', enableMfaRequest, {
                context: new HttpContext().set(
                    SkipErrorInterceptorContextToken,
                    (err) => this.isOTPInvalidError(err) || this.isOTPReuseError(err),
                ),
            }),
        );
        this.twoFactorRequiredChanged.emit(true);
    }

    public override async changePassword(passwordChange: PasswordChange): Promise<void> {
        const changePasswordRequest: ChangePasswordRequest = {
            ...passwordChange,
            newPassword: Utils.base64encode(passwordChange.newPassword),
            oldPassword: Utils.base64encode(passwordChange.oldPassword),
        };
        return firstValueFrom(
            this._httpClient.post<void>('/auth/change-password', changePasswordRequest, {
                context: new HttpContext().set(
                    SkipErrorInterceptorContextToken,
                    (err) => this.isOTPRequiredError(err) || this.isOTPInvalidError(err) || this.isOTPReuseError(err),
                ),
            }),
        );
    }

    public mapOTPError(err: any): never {
        if (this.isOTPInvalidError(err)) {
            throw new OTPInvalidError();
        } else if (this.isOTPRequiredError(err)) {
            throw new OTPRequiredError();
        } else if (this.isOTPReuseError(err)) {
            throw new OTPReuseError();
        } else {
            throw err;
        }
    }

    public isOTPRequiredError(err: any): err is HttpErrorResponse {
        return (
            err instanceof HttpErrorResponse &&
            err.status === HttpStatusCode.BadRequest &&
            err.error?.message === 'OTP is required'
        );
    }

    public isOTPInvalidError(err: any): err is HttpErrorResponse {
        return (
            err instanceof HttpErrorResponse &&
            err.status === HttpStatusCode.BadRequest &&
            err.error?.message === 'OTP is wrong'
        );
    }

    public isOTPReuseError(err: any): err is HttpErrorResponse {
        return (
            err instanceof HttpErrorResponse &&
            err.status === HttpStatusCode.BadRequest &&
            err.error?.message === 'OTP has already been used'
        );
    }
}
